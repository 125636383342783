import React from 'react';
import { Image } from '@wix/image';
import {
  getImageUri,
  getOuterUrl,
  getPostCover,
  getPostPageSectionUrl,
  isExperimentEnabled,
} from '@wix/communities-blog-client-common';
import usePermissions from '@app/external/common/hooks/use-permissions';
import { type NormalizedPost } from '@app/external/common/types';
import { OOI_EXPERIMENTS } from '../../../../experiments';
import Link from '../../../common/components/link/internal-link';
import { PostListItemVideoThumbnail } from '../../../common/components/post-list-item-video-thumbnail';
import { useSelector } from '../../../common/components/runtime-context';
import styles from './recent-post-list-item-cover.scss';

type Props = {
  post: NormalizedPost;
};

/** Assumes that post.coverImage.shouldRender === true */
const RecentPostListItemCover: React.FC<Props> = ({ post }) => {
  const postCover = getPostCover(post);

  const imageMetadata = postCover?.imageMetadata ?? post?.media?.wixMedia?.image!;
  const videoMetadata = postCover?.videoMetadata ?? post?.media?.embedMedia?.thumbnail!;
  const { can } = usePermissions();
  const sectionUrl = useSelector(getPostPageSectionUrl);
  const isPublic = can('share', 'post', post);
  const postLink = `/${post.slug}`;
  const isAvifEncodingEnabled = useSelector((state) =>
    isExperimentEnabled(state, OOI_EXPERIMENTS.USE_AVIF_ENCODING),
  );

  return (
    <Link tabIndex={-1} fullRoute={post.link} to={postLink}>
      {imageMetadata ? (
        <div className={styles.coverWrapper}>
          <Image
            uri={getImageUri(imageMetadata)!}
            width={imageMetadata?.width!}
            height={imageMetadata?.height!}
            alt=""
            displayMode="fill"
            className={styles.cover}
            targetWidth={450}
            targetHeight={253}
            socialAttrs={
              isPublic
                ? {
                    'data-pin-url': post.link || getOuterUrl(postLink, sectionUrl),
                  }
                : { 'data-pin-nopin': true }
            }
            encoding={isAvifEncodingEnabled ? 'AVIF' : undefined}
          />
        </div>
      ) : (
        <div className={styles.thumbContainer}>
          <div className={styles.thumbWrapper}>
            <PostListItemVideoThumbnail oembed={videoMetadata} withoutLabel={true} />
          </div>
        </div>
      )}
    </Link>
  );
};

export default RecentPostListItemCover;
