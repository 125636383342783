import React from 'react';
import classNames from 'classnames';
import { resolveId } from '@wix/communities-blog-client-common';
import useIsFeedDesignEnabled from '../../hooks/use-is-feed-design-enabled';
import usePermissions from '../../hooks/use-permissions';
import {
  getIsPrintEnabled,
  getIsSocialSharingEnabled,
  getSocialSharingProviders,
} from '../../selectors/app-settings-selectors';
import { getFeedColorClassName } from '../../services/layout-config';
import { getCommentCount, getViewCount } from '../../store/post-counters/post-counters-selectors';
import CategoryLabelList from '../category-label-list';
import { Counter } from '../counter';
import { LikeButtonWithCount } from '../like-button-with-count';
import PostLink from '../link/post-link';
import { useSettingsContext } from '../post/settings-context';
import PostIconRow from '../post-icon-row';
import PostPrintAction from '../post-print-action';
import PostSocialActions from '../post-social-actions';
import { useSelector } from '../runtime-context';
import { HorizontalSeparator } from '../separator';
import ViewCount from '../view-count';
import type { Props } from './types';
import styles from './post-main-actions-desktop.scss';

const PostMainActionsDesktop: React.FC<Props> = ({ post, commentsLink, layoutName }) => {
  const { showLikeCount, showCommentCount, showViewCount, showCategoryLabels } =
    useSettingsContext();
  const { canRender, canSee } = usePermissions();
  const { getPostClassName } = useIsFeedDesignEnabled();

  const isSocialSharingEnabled = useSelector(getIsSocialSharingEnabled);
  const enabledProviders = useSelector(getSocialSharingProviders);
  const printEnabled = useSelector(getIsPrintEnabled);
  const hasPrint = isSocialSharingEnabled && printEnabled;
  const viewCount = useSelector((state) => getViewCount(state, resolveId(post)));
  const totalComments = useSelector((state) => getCommentCount(state, resolveId(post)));

  const hasSocialLinks = canSee('share', 'post', post) && enabledProviders.length > 0;
  const categoriesCount = post?.categoryIds?.length ?? 0;
  const hasCategories = showCategoryLabels && categoriesCount > 0;
  const isMetadataVisible = showLikeCount || showCommentCount || showViewCount;
  const hasActionSection = hasSocialLinks || hasPrint || hasCategories;
  const noSeparators = !hasActionSection && !isMetadataVisible;
  const commentCount = showCommentCount ? (
    <Counter i18nKey="comment-count.label" showZero count={totalComments} />
  ) : null;

  return (
    <div
      className={classNames(
        styles.container,
        { [styles.noSeparators]: noSeparators },
        getPostClassName(
          'description-font',
          getFeedColorClassName(layoutName, 'description-color'),
        ),
      )}
      data-hook="post-main-actions-desktop"
    >
      {hasActionSection && [
        <HorizontalSeparator key="separator" />,
        <div key="div" className={classNames(styles.section, styles.mainSection)}>
          <PostIconRow postId={post.id!}>
            {hasSocialLinks &&
              canRender('share', 'post', post, () => (
                <PostSocialActions
                  postSlug={post.slug}
                  postId={resolveId(post)}
                  enabledProviders={enabledProviders}
                />
              ))}
            {hasPrint && <PostPrintAction postId={resolveId(post)} />}
          </PostIconRow>
          {hasCategories && <CategoryLabelList post={post} />}
        </div>,
      ]}
      {isMetadataVisible && [
        <HorizontalSeparator key="separator" />,
        <div
          key="div"
          className={classNames(styles.section, styles.bottomSection)}
          data-hook="post-main-actions__stats"
        >
          <div data-hook="post-stats" className={styles.stats}>
            {showViewCount && <ViewCount count={viewCount} />}
            {showCommentCount &&
              (commentsLink ? (
                <PostLink
                  postLink={commentsLink}
                  postPath={post.slug!}
                  className={classNames(
                    styles.comments,
                    getPostClassName('link-hashtag-hover-color'),
                  )}
                >
                  {commentCount}
                </PostLink>
              ) : (
                commentCount
              ))}
          </div>
          {showLikeCount && (
            <LikeButtonWithCount className={styles.likeButton} postId={resolveId(post)!} />
          )}
        </div>,
      ]}
    </div>
  );
};

export default PostMainActionsDesktop;
